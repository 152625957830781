import React, { useEffect, forwardRef } from 'react';
import BaseGrid from '../BaseGrid';
import GridLoader from '../GridLoader';
import GridErrorMessage from '../GridErrorMessage';
import useGridData from './useGridData';
import requestStatuses from './requestStatuses';
import './style.scss';

const PageGrid = ({
  name,
  getData,
  columns,
  searchParams,
  isLoading,
  initialSort,
  initialPage,
  reloadOnSort,
  needReload = false,
  onReload,
  onLoaded,
  pageable = true,
  onItemChange,
  editField,
  itemInEdit,
  identifierColumn,
  onChangesSaved,
  expandField,
  emptyObjectFactory,
  customChangedHandlers = {},
  setInEdit,
  onAddMultiple,
  cellRender,
  dataState,
  onDataStateChange,
  process,
  columnsToDisplay,
  filteringOptions,
  ...props
}, ref) => {
  const config = {
    initialSort,
    initialPage,
    reloadOnSort,
  };

  const {
    status,
    items,
    total,
    error,
    sort,
    onSortChange,
    page,
    onPageChange,
    reload,
  } = useGridData(getData, searchParams, config, columns, process, dataState);

  useEffect(() => {
    if (status === requestStatuses.SUCCESS) {
      if (onLoaded) {
        onLoaded(items, searchParams);
      }
    }
  }, [status]);

  useEffect(() => {
    if (needReload) {
      reload();
      onReload();
    }
  }, [needReload]);

  if (status === requestStatuses.INIT || status === requestStatuses.PENDING || isLoading) {
    return (
      <div className="old-page-grid">
        <GridLoader />
      </div>
    );
  }

  if (status === requestStatuses.FAILED) {
    return (
      <GridErrorMessage name={name} error={error} />
    );
  }

  if (status === requestStatuses.SUCCESS) {
    return (
      <div className="old-page-grid">
        <BaseGrid
          resizable
          name={name}
          data={items}
          columns={columns}
          pageable={pageable}
          sortable
          sort={sort}
          onSortChange={onSortChange}
          skip={page.skip}
          pageSize={page.take}
          total={total}
          onPageChange={onPageChange}
          cellRender={cellRender}
          {...props}
        />
      </div>
    );
  }
};

export default forwardRef(PageGrid);
