/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import Media from 'react-media';
import Modal from 'shared/modals/Modal';
import Icon from 'shared/icons/IconComponent';
import Select from 'shared/inputs/Select';

const searchTypeOptions = [
  { label: 'Equipment Number', value: 'equipmentNumber' },
  { label: 'BOL/BKG/Ref', value: 'primaryReferenceNumber' },
  { label: 'PO Number', value: 'poNumber' },
];

const NavMenu = ({ location, history, Collapsed }) => {
  // Page Data
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [search, setSearch] = useState(params.query && params.query.length ? params.query : '');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchType, setSearchType] = useState('equipmentNumber');
  const [selectedRow, setSelectedRow] = useState();

  // sidebar/modal settings
  const [modalShowing, setModalShowing] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(Collapsed);

  useEffect(() => {
    setIsCollapsed(Collapsed);
  }, [Collapsed]);

  const [isLoading, setIsLoading] = useState(true);

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    setModalShowing(!modalShowing);
  };
  const handleClickSearch = () => {
    history.push('/search');
  };
  const handleClickPrint = () => {
    history.push('/search?status=1');
  };
  return (
    <Media queries={{ small: '(max-width: 699px)', medium: '(min-width: 700px) and (max-width: 1199px)', large: '(min-width: 1200px)' }}>
      {(matches) => (
        <>
          {matches.small && (
            <Modal
              isOpen={modalShowing}
              handleClose={() => handleToggleSidebar()}
              containerStyle='large'
              className='search-page-modal'
            >
              <div className='modal-header'>
                <h5 className='modal-title' id='search-modal'>
                  Search
                </h5>
                <button className='button button--large button--close' onClick={() => handleToggleSidebar()} />
              </div>
              <section className='modal-body'>
                <div className='search-inputs'>
                  <Select
                    className='select search-type'
                    options={searchTypeOptions.map((t) => ({ label: t.description, value: t.value }))}
                    value={searchType}
                    onChange={(selected) => {
                      setSearchType(selected);
                    }}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                  />
                  <div className='search-input-wrapper'>
                    <input
                      className='search-input'
                      placeholder='Search for Container, PRO, BKG, BOL or PO'
                      id='modal-search'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyUp={(e) => {
                        if ((e.which === 13 || e.keyCode === 13) && search.length) {
                          // handleSearch();
                        }
                      }}
                    />
                    <Icon
                      onClick={() => {
                        if (search.length) {
                          // handleSearch();
                        }
                      }}
                      icon='search'
                      className='search-icon'
                    />
                  </div>
                </div>
                {hasSearched && !loading && (
                  <div className='results-count'>
                    {results.length}
                    {' '}
                    Results
                  </div>
                )}
                {hasSearched && (
                  <div className={`results-container ${isCollapsed ? 'collapsed' : 'full-width'}`}>
                    {results.length
                      ? results.map((r, i) => (
                        <div
                          key={`${r.proNumber}_${i}`}
                          className={`result ${selectedRow === i ? 'selected' : ''}`}
                          onClick={() => {
                            if (selectedRow !== i) {
                               setSelectedRow(i);
                              // handleSingleSearch(r.proNumber, matches.large);
                            }
                          }}
                        >
                          <div className='result-column pro'>
                            <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>PRO#</div>
                            <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.proNumber}</div>
                          </div>
                          <div className='result-column equip'>
                            <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>EQUIP#</div>
                            <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.containerNumber}</div>
                          </div>
                          <div className='result-column'>
                            <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>REF#</div>
                            <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.primaryReferenceNumber}</div>
                          </div>
                        </div>
                      )) : null}
                  </div>
                )}
              </section>
              <section className='modal-footer toolbar toolbar--xxs'>
                <button
                  id='close-search-modal-button'
                  type='button'
                  className='toolbar--button--margin button button--large button--white text-align--center'
                  onClick={() => handleToggleSidebar()}
                >
                  Cancel
                </button>
                <button
                  id='search-modal-button'
                  type='button'
                  className='toolbar--button--margin button button--large button--primary-color text-align--center margin--none--xxs'
                  onClick={() => {
                    // handleSearch()
                  }}
                >
                  Submit
                </button>
              </section>
            </Modal>
          )}
          {(matches.medium || matches.large) && (
            <div className={`${matches.medium ? 'medium-sidebar' : 'large-sidebar'} ${isCollapsed ? 'collapsed' : 'full-width'}`}>
              <div
                className={`toggle-button ${isCollapsed ? 'collapsed' : 'full-width'}`}
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <Icon
                  icon='angle-right'
                  className={`icon--font-medium caret-icon ${isCollapsed ? '' : 'rotated'}`}
                  position='none'
                />

              </div>

              <div
                className={`toggle-button ${isCollapsed ? 'search' : 'hidden'}`}
                onClick={() => handleClickSearch()}
              >
                <Icon
                  icon='search'
                  className="icon--font-medium caret-icon"
                  position='none'
                />

              </div>
              <div
                className={`toggle-button ${isCollapsed ? 'print' : 'hidden'}`}
                onClick={() => handleClickPrint()}
              >
                <Icon
                  icon='print'
                  className="icon--font-medium caret-icon"
                  position='none'
                />

              </div>

            </div>
          )}
        </>
      )}
    </Media>

  );
};

export default withRouter(NavMenu);
